<script setup lang="ts">
const staticLinks = useStaticLinks();
const route = useRoute();
const { t } = useI18n({
  useScope: 'local',
});

const isAuthenticated = useAuthState();
const accountStore = useAccountStore();
const roleId = computed(() => accountStore.user?.roleId || '');

const props = withDefaults(
  defineProps<{
    showLabel?: boolean;
    showIcon?: boolean;
  }>(),
  {
    showLabel: true,
    showIcon: true,
  },
);
</script>

<template>
  <tw-ui-action :to="`${staticLinks.billing}?refPage=${route.fullPath}&refElement=navbar-activate-subscription-action`"
    class="activate-subscription" variant="button-outlined" is="link"
    v-if="isAuthenticated && roleId !== 'new-full-access'">
    <Icon name="bi:rocket-takeoff" v-if="props.showIcon" />
    <span v-if="props.showLabel"> {{ t('button_text') }}</span>
  </tw-ui-action>
</template>

<style scoped lang="scss">
@import '~/assets/styles/tw-variables.scss';

.action--button-outlined,
.action--button-outlined:hover {
  border-color: $success !important;
  color: $success !important;
  background-color: $white !important;
  padding: 0.5rem 1rem;
}

.action--button-outlined {
  span {
    font-size: 1rem;
    text-transform: none !important;
  }
}
</style>

<i18n lang="json">
{
  "de": {
    "button_text": "Pro"
  },
  "en": {
    "button_text": "Pro"
  }
}
</i18n>
